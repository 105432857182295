import React,{useEffect} from "react";
// import "../../../node_modules/bootstrap/scss/bootstrap.scss";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css.map";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
// import "../../assets/css/bootstrap.min.css";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';



import $ from 'jquery';

import Header from "../inc/Header";
import Footer from "../inc/Footer";
import videobanner from "../../assets/video/video.mp4";
import tabimg from "../../assets/img/common.png";
import tabimg_1 from "../../assets/img/rare.png";
import tabimg_2 from "../../assets/img/epic.png";
import tabimg_3 from "../../assets/img/legengary.png";
import tabimg1 from "../../assets/img/image1.png";
import ticket from "../../assets/img/ticketssss3 1.png";
import roadmap from "../../assets/img/roadmap.png";
import faq from "../../assets/img/common-ques-img.png";

import partner1 from "../../assets/img/partner1.png";
import partner2 from "../../assets/img/partner2.png";
import partner3 from "../../assets/img/partner3.png";
import partner4 from "../../assets/img/partner4.png";
import partner5 from "../../assets/img/partner5.png";
import partner6 from "../../assets/img/partner6.png";
import partner7 from "../../assets/img/partner7.png";
import footertop from "../../assets/img/footer-bg.png";
import team1 from "../../assets/img/team1.png";
import team2 from "../../assets/img/team2.png";
import team3 from "../../assets/img/team3.png";
import cloudimg from "../../assets/img/cloud1.png";
import overlay from "../../assets/img/overlay.png";

var sectionStyle = {

  backgroundImage: `url(${tabimg1})`
};


const Home = (props) => {

  useEffect(() => {
    



    $(".tab a").click(function(event) {
      event.preventDefault();
      $(this).parent().addClass("current");
      $(this).parent().siblings().removeClass("current");
      var tab = $(this).attr("href");
      $(".tab_view").not(tab).css("display", "none");
      $(tab).fadeIn();
    });

    // $('#navigation nav').slimNav_sk78();

    $('.accordion').find('.accordion-toggle').click(function() {
        $(this).next().slideToggle('600');
        $(".accordion-content").not($(this).next()).slideUp('600');
    });
    $('.accordion-toggle').on('click', function() {
        $(this).toggleClass('active').siblings().removeClass('active');
    });

// line on scroll
    function addSticky() {
      $('.rarities').each(function() {
        var scrollerAnchor = $(this).offset().top;
        if (window.scrollY >= scrollerAnchor) {
          $(this).addClass('fix-it');
        } else {
          $(this).removeClass('fix-it');
        }
      });
    }    
    $(window).scroll(function() {
      addSticky();
    });
// line on scroll

//scroll-----------------------
    
    
  }, []);
  const state= {
    responsive:{
        0: {
            items: 1,
        },
        450: {
            items: 3,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    },
}
  return ( 
    <>
    <Header/>
        <div>
 
  <div>
  
  

<div id="panel1" className="panel">
  <video className="background" width={400} autoPlay muted loop><source src={videobanner} type="video/mp4" /></video>

  <div class="overlay">
  <img src={overlay} alt="" />
  </div>
 </div>
    
    <div id="panel2" className="panel">
    <div class="cloud"><img src={cloudimg} alt="" /></div>
    <div class=" cloud2"><img src={cloudimg} alt="" /></div>

    <div className="rarities" id="Rarities" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12" data-aos="fade-down"  data-aos-duration="1500">
              <h2>sky <span>verse</span> </h2>
              <h4>4 Rarities</h4>
            </div>
          </div>
          <div className="tab_services">
            <div className="row">
              <div className="col-lg-3">
                <ul className="tab">
                  <li className="current">
                    <a className="btn" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1500" href="#tab1">Common</a>
                  </li>
                  <li>
                    <a className="btn" data-aos="fade-right" data-aos-delay="200" data-aos-duration="2000" href="#tab2">Rare</a>
                  </li>
                  <li>
                    <a className="btn" data-aos="fade-right" data-aos-delay="300" data-aos-duration="2500" href="#tab3">Epic</a>
                  </li>
                  <li>
                    <a className="btn" data-aos="fade-right" data-aos-delay="400" data-aos-duration="3000" href="#tab4">Legendary</a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-9">
                <div className="tab_view" id="tab1" style={{display: 'block'}}>
                  <div className="row align-items-center">
                    <div className="col-lg-7 object">
                      <img src={tabimg} alt="" />
                    </div>
                    <div className="col-lg-5">
                    <div className="tabdesc">
                      <div className="linesec"><span className="line2"></span><span className="line3"></span></div>
                      <ul className="description-list">
                          <li>Sky Island is the first-ever virtual land which is set in the skies. </li>
                          <li>Each Sky Island is fully packed with utility, rooting from $SKY usage that grants exposure to the whole of SkyVerse’s Ecosystem.</li>
                          <li>There are 4 rarities - Common, Rare, Epic & Legendary. </li>
                          <li>The higher the rarity of a Sky Island, the higher the $SKY Yield.
                          </li>
                          <li>
                          Each Sky Island can be staked to earn $SKY.
                          </li>
                          <li>Owning a Sky Island grants you Exclusive Access to the Sky Ticketer
                          </li>
                          <li>Having a Sky Island gives you priority reservation for the upcoming SkyVerse Part 2: Characters project.
</li>
                        </ul>
                    </div>
                      
                    </div>
                  </div>
                </div>
                <div className="tab_view" id="tab2">
                <div className="row align-items-center">
                    <div className="col-lg-7 object">
                      <img src={tabimg_1} alt="" />
                    </div>
                    <div className="col-lg-5">
                    <div className="tabdesc">
                      <div className="linesec"><span className="line2"></span><span className="line3"></span></div>
                      <ul className="description-list">
                          <li>Sky Island is the first-ever virtual land which is set in the skies. </li>
                          <li>Each Sky Island is fully packed with utility, rooting from $SKY usage that grants exposure to the whole of SkyVerse’s Ecosystem.</li>
                          <li>There are 4 rarities - Common, Rare, Epic & Legendary. </li>
                          <li>The higher the rarity of a Sky Island, the higher the $SKY Yield.
                          </li>
                          <li>
                          Each Sky Island can be staked to earn $SKY.
                          </li>
                          <li>Owning a Sky Island grants you Exclusive Access to the Sky Ticketer
                          </li>
                          <li>Having a Sky Island gives you priority reservation for the upcoming SkyVerse Part 2: Characters project.
</li>
                        </ul>
                    </div>
                      
                    </div>
                  </div>
                </div>
                <div className="tab_view" id="tab3">
                <div className="row align-items-center">
                    <div className="col-lg-7 object">
                      <img src={tabimg_2} alt="" />
                    </div>
                    <div className="col-lg-5">
                    <div className="tabdesc">
                      <div className="linesec"><span className="line2"></span><span className="line3"></span></div>
                      <ul className="description-list">
                          <li>Sky Island is the first-ever virtual land which is set in the skies. </li>
                          <li>Each Sky Island is fully packed with utility, rooting from $SKY usage that grants exposure to the whole of SkyVerse’s Ecosystem.</li>
                          <li>There are 4 rarities - Common, Rare, Epic & Legendary. </li>
                          <li>The higher the rarity of a Sky Island, the higher the $SKY Yield.
                          </li>
                          <li>
                          Each Sky Island can be staked to earn $SKY.
                          </li>
                          <li>Owning a Sky Island grants you Exclusive Access to the Sky Ticketer
                          </li>
                          <li>Having a Sky Island gives you priority reservation for the upcoming SkyVerse Part 2: Characters project.
</li>
                        </ul>
                    </div>
                      
                    </div>
                  </div>
                </div>
                <div className="tab_view" id="tab4">
                <div className="row align-items-center">
                    <div className="col-lg-7 object">
                      <img src={tabimg_3} alt="" />
                    </div>
                    <div className="col-lg-5">
                    <div className="tabdesc">
                      <div className="linesec"><span className="line2"></span><span className="line3"></span></div>
                      <ul className="description-list">
                          <li>Sky Island is the first-ever virtual land which is set in the skies. </li>
                          <li>Each Sky Island is fully packed with utility, rooting from $SKY usage that grants exposure to the whole of SkyVerse’s Ecosystem.</li>
                          <li>There are 4 rarities - Common, Rare, Epic & Legendary. </li>
                          <li>The higher the rarity of a Sky Island, the higher the $SKY Yield.
                          </li>
                          <li>
                          Each Sky Island can be staked to earn $SKY.
                          </li>
                          <li>Owning a Sky Island grants you Exclusive Access to the Sky Ticketer
                          </li>
                          <li>Having a Sky Island gives you priority reservation for the upcoming SkyVerse Part 2: Characters project.
</li>
                        </ul>
                    </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      {/* https://codepen.io/yudizsolutions/pen/rNmzxWE */}
{/* <div class="flydragon">
<img src={dragon} alt="" />
</div> */}
    
    <div className="skyverse-ticket " id="Ticketer">
        <h2><span>Skyverse</span>Ticketer</h2>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="row align-items-center">
                <div className="col-md-6 object">
                  <img src={ticket} alt="" />
                </div>
                <div className="col-md-4" data-aos="fade-left" data-aos-duration="1000">
                  <p>The SkyTicketer is a one-stop Utility Marketplace where holders can spend their $SKY token for raffle tickets to win highly coveted WL Opportunities, Alpha Membership Passes and Hidden Features. The SkyTicketer offers 2 types of raffle tickets - Regular & Golden. Regular tickets count as a normal entry ticket to any sort of WL/Alpha pass raffle while Golden tickets automatically secures the win. This is to ensure all types of holders are able to maximise their odds of profits according to the spending strategy they wish to adopt.
</p>
                  <a className="btn" href="#">Get it now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="panel4" className="panel">
    <div className="roadmap" id="roadmap">
  <h2>Roadmap</h2>
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <ul className="roadmap-list">
          <li data-aos="fade-right" data-aos-duration="1000" data-aos-delay="100">
            <h4>01</h4>
            <div className="list-box">
              <h3>Mint Release</h3>
              <p>5,000 Sky Islands ready to be minted on the Ethereum Blockchain.
</p>
            </div>
          </li>
          <li data-aos="fade-right" data-aos-duration="2000" data-aos-delay="200">
            <h4>02</h4>
            <div className="list-box">
              <h3>SkyVault </h3>
              <p>95% of funds will go into SkyVault for development, refer to Whitepaper for specifics.</p>
            </div>
          </li>
          <li data-aos="fade-right" data-aos-duration="3000" data-aos-delay="300">
            <h4>03</h4>
            <div className="list-box">
              <h3>Great Reveal</h3>
              <p>  Reveal will take place on OpenSea, holders will be able to find out the rarity of their Sky Island, linked closely to tokenomics.
</p>
            </div>
          </li>
          <li data-aos="fade-right" data-aos-duration="4000" data-aos-delay="400">
            <h4>04</h4>
            <div className="list-box">
              <h3>Staking</h3>
              <p>Holders can choose to stake their Sky Islands for $SKY</p>
            </div>
          </li>
          <li data-aos="fade-right" data-aos-duration="5000" data-aos-delay="500">
            <h4>05</h4>
            <div className="list-box">
              <h3>SkyTicketer Marketplace </h3>
              <p>Holders will gain access to the Utility Marketplace to spend their $SKY tokens for Whitelist Spots and Alpha Memberships.</p>
            </div>
          </li>
          <li data-aos="fade-right" data-aos-duration="6000" data-aos-delay="600">
            <h4>06</h4>
            <div className="list-box">
              <h3>Metaverse Development</h3>
              <p>Through the use of SkyVault, building on SkyPixel, SkyWorlds and Sandbox will be much more efficient, refer to Whitepaper for the specifics.</p>
            </div>
          </li>
        </ul>
      </div>
      <div className="col-md-6 object">
        <div className="roadmap-img">
          
          <img src={roadmap} alt="" />
        </div>
      </div>
    </div>
  </div>
</div>

    
   
      
<div className="accordian">
  <div className="container">
    <div className="row">
      <div className="col-md-6 object order-2 order-lg-1">
        <img src={faq} alt="" />
      </div>
      <div className="col-md-6 order-1 order-lg-2">
        <h2><span>Common</span> Questions</h2>
        <div className="accordion">
          <h4 className="accordion-toggle">What is Mint Date?</h4>
          <div className="accordion-content">
            <p>
            Mid April (Will announce exact date 1 week before)
            </p>
          </div>
          <h4 className="accordion-toggle">What is the Supply?</h4>
          <div className="accordion-content">
            <p>
            Only 5,000 in existence
            </p>
          </div>
          <h4 className="accordion-toggle">What is the Mint Price?</h4>
          <div className="accordion-content">
            <p>
            0.1 ETH 
            </p>
          </div>
          <h4 className="accordion-toggle">How many can a whitelisted wallet mint?</h4>
          <div className="accordion-content">
            <p>
            Max Mint per wallet: One (1)

            </p>
          </div>
          <h4 className="accordion-toggle">What is the Whitelist/Public allocation?</h4>
          <div className="accordion-content">
            <p>
            100% Whitelist Sale (5,000 Whitelist Spots)

            </p>
          </div>
          <h4 className="accordion-toggle">When will Reveal happen?</h4>
          <div className="accordion-content">
            <p>
            Will take place after 48 hours of closing of mint
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
   
    <div className="partners">
        <h2>Our partners</h2>
        <div className="container-fluid">
             <div className="row">
                <div className="col-md-12">  
                  
                <OwlCarousel className="partner owl-theme" rtlClass='owl-rtl'	 items={5} autoplayTimeout={2000} smartSpeed={3250} autoplay={true} dots={false} loop={true}  responsive={state.responsive} > 
                                      
                                      <div className="item ">
                                        <img src={partner1} alt="" />
                                      </div>
                                      <div className="item ">
                                        <img src={partner2} alt="" />
                                      </div>
                                      <div className="item ">
                                        <img src={partner3} alt="" />
                                      </div>
                                      <div className="item ">
                                        <img src={partner4} alt="" />
                                      </div>
                                      <div className="item ">
                                        <img src={partner5} alt="" />
                                      </div>
                                      <div className="item ">
                                        <img src={partner6} alt="" />
                                      </div>
                                      <div className="item ">
                                        <img src={partner7} alt="" />
                                      </div>
                                      <div className="item ">
                                        <img src={partner1} alt="" />
                                      </div>
                                      <div className="item ">
                                        <img src={partner2} alt="" />
                                      </div>
                                      <div className="item ">
                                        <img src={partner3} alt="" />
                                      </div>
                                      <div className="item ">
                                        <img src={partner4} alt="" />
                                      </div>
                                </OwlCarousel>

                    <div className="partner2 owl-carousel">
                    <div className="item ">
                            <img src={partner1} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner2} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner3} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner4} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner5} alt="" />
                          </div>
                          <div className="item ">
                             <img src={partner6} alt="" />
                          </div>
                          
                         
                          <div className="item ">
                            <img src={partner1} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner2} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner3} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner7} alt="" />
                          </div>
                    </div>
                             
                    <OwlCarousel className="partner owl-theme" rtlClass='owl-rtl'	 items={5} autoplayTimeout={3000} smartSpeed={4250} autoplay={true} dots={false} loop={true} responsive={state.responsive}> 
                          <div className="item ">
                            <img src={partner7} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner6} alt="" />
                          </div>       
                          <div className="item ">
                            <img src={partner5} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner4} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner3} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner2} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner1} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner5} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner4} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner3} alt="" />
                          </div>
                          <div className="item ">
                            <img src={partner2} alt="" />
                          </div>
                    </OwlCarousel>
                
                </div>
              </div>
        </div>            
    </div>
<div class="theteam">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h2>Our Team</h2>
      </div>
    </div>
    <div class="row justify-content-center">
      
      <div class="col-lg-3">
        <div class="team_block">
          <div class="teamimg">
            <img src={team2} alt="" />
          </div>
          <h3>Jeppy</h3>
          <p>Creator/Builder</p>
          <a href="https://twitter.com/JeppyNFT" target="_blank"><i class="fa fa-twitter"></i></a>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team_block">
          <div class="teamimg">
            <img src={team1} alt="" />
          </div>
          <h3>Berela</h3>
          <p>Artist</p>
          {/* <a href="#"><i class="fa fa-twitter"></i></a> */}
          <a href="https://www.instagram.com/poly_berela/" target="_blank"><i class="fa fa-instagram"></i></a>
        </div>
      </div>
      <div class="col-lg-3">
        <div class="team_block">
          <div class="teamimg">
            <img src={team3} alt="" />
          </div>
          <h3>Binoy</h3>
          <p>Designer</p>
          <a href="https://www.facebook.com/binoy.dey.549/" target="_blank"><i class="fa fa-facebook-f"></i></a>
        </div>
      </div>
      {/* <div class="col-lg-3">
        <div class="team_block">
          <div class="teamimg">
            <img src={team2} alt="" />
          </div>
          <h3>JARED</h3>
          <p>Technical Development</p>
          <a href="#"><i class="fa fa-twitter"></i></a>
        </div>
      </div> */}
    </div>
  </div>
</div>
 <div className="comunity">
  <div className="container">
    <div className="row">
      <div className="community-head">
        <h2><span>Join the</span>community</h2>
        <p>Follow our twitter</p>
      </div>
      <div className="col-md-12">
        <div className="comunity-container">
          <div className="row align-items-center">
            <div className="col-md-5">
              <div className="comunity-box">
                <p>
                  {/* Connect with the rest of our thriving NANOPASS community.
                  Join the action on our discord server to get the latest news and announcements. */}
                  Be early to all the latest news and activities happening on SkyVerse by following our Twitter (or not, it is up to you).
                </p>
              </div>
            </div>
            <div className="col-md-7">
              <div className="button-area1" data-aos="fade-left" data-aos-duration="3000" >
              <a className="mint-but" href="https://twitter.com/SkyVerseNFT" target="_blank"><i className="fa fa-twitter" />follow twitter</a><span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>


    
    <section className="main-section" style={sectionStyle}></section>
      
      
  




    






<div className="footertops">
  <img src={footertop} alt="" />
</div>





    
    

      
       </div>
     </div>
       
<Footer/>

</>
  );

};

export default Home;