import React from "react";
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
// import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
  <Router>
    <Routes>
      <Route path="/" exact element={<Home />} />
    </Routes>
</Router>
  );
}

export default App;
