import React from "react";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";
import logoimg from "../../assets/img/logo-main.png";
// import fontawesome from '@fortawesome/fontawesome';
// import FontAwesomeIcon from '@fortawesome/react-fontawesome';
// import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';


// fontawesome.library.add(faCheckSquare, faCoffee);

const Header = (props) => {

  return ( 
<div className="container">
  <a href="#" class="blankdiv">
  <header>
    <div className="row">
      <div className="col-lg-4 col-0">
        <div className="menu-sec">
          <div id="navigation">
            <nav>
              <ul>
                <li className="current-menu-item"><a href="#Rarities">Rarities</a></li>
                <li><a href="#Ticketer">Ticketer</a></li>
                <li><a href="#roadmap">roadmap</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-5">
        <div className="logo-sec">
          <a href="#"><img className="logo" src={logoimg} alt="" /></a>
        </div>
      </div>
      <div className="col-lg-4 col-7">
        <div className="social-sec">
          <ul>
          {/* <FontAwesomeIcon icon={['fab', 'apple']} />
      <FontAwesomeIcon icon={['fab', 'microsoft']} />
      <FontAwesomeIcon icon={['fab', 'google']} />
      <FontAwesomeIcon icon="check-square" /><br /><br />
    <FontAwesomeIcon icon="coffee" /> */}
         
            {/* <li><a href="#"><i className="fa fa-facebook-f" /></a></li> */}
            <li><a href="https://twitter.com/SkyVerseNFT" target="_blank"><i className="fa fa-twitter" /></a></li>
            <li><a href="https://docs.skyversenft.com/overview/introduction" target="_blank">White Paper</a></li>
            {/* <li><a href="#"><i className="fa fa-youtube" /></a></li> */}
          </ul>
        </div>
      </div>
    </div>
  </header>
  </a>
</div>

  );
};
export default Header