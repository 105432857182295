import React from "react";
import "../../assets/css/style.css";
import "../../assets/css/responsive.css";


const Footer = (props) => {
  return (
    <div>
      <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="footersocial">
              <ul>
                <li>
                  <a href="https://twitter.com/SkyVerseNFT" target="_blank">
                   <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="https://docs.skyversenft.com/overview/introduction" target="_blank">
                  White paper
                  </a>
                </li>
              </ul>
            </div>
            <p>© copyright skyverse 2022. All right reserved</p>
          </div>
        </div>
      </div>

        
       
      </footer>
    </div>
  );
};
export default Footer;
